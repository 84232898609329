import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import GradientAnimation from '../components/GradientAnimation'
import '../style/index.scss'

const Index = () => {
    const { flowCircuits, hvakr, traqpad } = useStaticQuery(graphql`
        {
            flowCircuits: file(relativePath: { eq: "flow-circuits.svg" }) {
                publicURL
            }
            hvakr: file(relativePath: { eq: "hvakr.svg" }) {
                publicURL
            }
            traqpad: file(relativePath: { eq: "traqpad.svg" }) {
                publicURL
            }
        }
    `)
    return (
        <Layout>
            <SEO />
            <section className='Hero'>
                <div className='background-container'>
                    <GradientAnimation />
                </div>
                <StaticImage
                    className='profile'
                    src='../images/profile-fc.png'
                    alt=''
                    loading='eager'
                    placeholder='blurred'
                    layout='fullWidth'
                    style={{ minWidth: 200, minHeight: 200 }}
                    imgStyle={{ objectFit: 'contain' }}
                />
                <div className='text'>
                    <h2>Let's build something together.</h2>
                    <Link to='/portfolio'>
                        <h3>See My Portfolio</h3>
                    </Link>
                </div>
            </section>
            <section className='Quicklist'>
                <a href='https://www.flowcircuits.com'>
                    <img
                        src={flowCircuits.publicURL}
                        width={128}
                        alt='Flow Circuits'
                    />
                </a>
                <a href='https://www.hvakr.com'>
                    <img src={hvakr.publicURL} width={128} alt='HVAKR' />
                </a>
                <a href='https://www.traqpad.com'>
                    <img src={traqpad.publicURL} width={128} alt='Traqpad' />
                </a>
            </section>
        </Layout>
    )
}

export default Index
