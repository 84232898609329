import React, { useEffect, useRef } from 'react'

interface Point {
    x: number
    y: number
}

const GradientAnimation = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const requestIdRef = useRef<number | undefined>()
    const timeRef = useRef<number>(0)

    const rBase = 87
    const gBase = 116
    const bBase = 140

    const rAmp = 15
    const gAmp = 20
    const bAmp = 15

    const getColor = (point: Point, time: number) => ({
        r: Math.floor(
            rBase +
                rAmp *
                    Math.cos(
                        (point.x * point.x - point.y * point.y) / 300 + time
                    )
        ),
        g: Math.floor(
            gBase +
                gAmp *
                    Math.sin(
                        (point.x * point.x * Math.cos(time / 4) +
                            point.y * point.y * Math.sin(time / 3)) /
                            300
                    )
        ),
        b: Math.floor(
            bBase +
                bAmp *
                    Math.sin(
                        5 * Math.sin(time / 9) +
                            ((point.x - 100) * (point.x - 100) +
                                (point.y - 100) * (point.y - 100)) /
                                1100
                    )
        ),
    })

    const renderFrame = (context: CanvasRenderingContext2D) => {
        Array.from(Array(32).keys()).forEach(x =>
            Array.from(Array(32).keys()).forEach(y => {
                const point = { x, y }
                const color = getColor({ x, y }, timeRef.current)
                context.fillStyle = `rgb(${color.r}, ${color.g}, ${color.b})`
                context.fillRect(point.x, point.y, 10, 10)
            })
        )
    }

    const tick = () => {
        const context = canvasRef.current?.getContext('2d')
        if (!context) return
        renderFrame(context)
        timeRef.current += 0.02
        requestIdRef.current = requestAnimationFrame(tick)
    }

    useEffect(() => {
        requestIdRef.current = requestAnimationFrame(tick)
        return () => {
            requestIdRef.current !== undefined &&
                cancelAnimationFrame(requestIdRef.current)
        }
    }, [])

    return (
        <canvas
            ref={canvasRef}
            width={32}
            height={32}
            style={{ width: '100%', height: '100%' }}
        />
    )
}

export default GradientAnimation
